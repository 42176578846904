import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import '../App.css';
import Masonry from '@mui/lab/Masonry';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {Dialog, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useMediaQuery } from '@mui/material';


function Photos({locationMap, filmTypeMap, timeMap}) {
    const [locationOpen, setLocationOpen] = React.useState(false);
    const [timeOpen, setTimeOpen] = React.useState(false);
    const [filmTypeOpen, setFilmTypeOpen] = React.useState(true);
    const [currentTab, setCurrentTab] = React.useState("filmType");
    const [currentClick, setCurrentClick] = React.useState("Portra 400(120)");
    const [imageOpen, setImageOpen] = React.useState(false);
    const [selectedImage, setSelectedImage] = React.useState("");

    const expandLocation = () => {setLocationOpen(!locationOpen);};
    const expandFilmType = () => {setFilmTypeOpen(!filmTypeOpen);};
    const expandTime = () => {setTimeOpen(!timeOpen);};
    const locationClick = (loc) => {
        setCurrentTab("location");
        setCurrentClick(loc);
    };
    const filmTypeClick = (type) => {
        setCurrentTab("filmType");
        setCurrentClick(type);
    };
    const timeClick = (time) => {
        setCurrentTab("time");
        setCurrentClick(time);
    };
    const imageClick = (image) => {
        setImageOpen(true);
        setSelectedImage(image);
    };
    const imageCloseClick = (image) => {
        setImageOpen(false);
        // setSelectedImage("");
    };

    const isMobile = useMediaQuery('(max-width:768px)');
    const spacing = isMobile ? 1 : 2;

    return (
        <Box className="content">
            <Grid container spacing={2} style={{height: '100%'}}>
                <Grid item xs={3} style={{textAlign: 'center'}}>
                    <Box sx={{ width: '100%', height: '100%', borderRight: 1, borderColor: 'divider' }}>
                        <List sx={{ width: '100%', paddingTop: 0}} component="nav">
                            <ListItemButton onClick={expandLocation}>
                                <ListItemText className="listFatherButton" primary="By Location" disableTypography/>
                                {locationOpen ? <ExpandLess className="icon"/> : <ExpandMore className="icon"/>}
                            </ListItemButton>
                            <Collapse in={locationOpen} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {[...locationMap.keys()].map((location, index) => (
                                        <ListItemButton sx={{backgroundColor: location===currentClick? 'lightgrey':'transparent'}} key={index} onClick={()=>locationClick(location)}>
                                            <ListItemText className="listChildButton" primary={
                                                isMobile?
                                                    location
                                                    :
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        width: '90%'
                                                    }}>
                                                        <span>{location}</span>
                                                        <span style={{fontSize: '0.8rem', alignContent: 'center'}}>{locationMap.get(location).length}</span>
                                                    </div>
                                            } disableTypography/>
                                        </ListItemButton>
                                    ))}
                                </List>
                            </Collapse>

                            <ListItemButton onClick={expandFilmType}>
                                <ListItemText className="listFatherButton" primary="By Film Type" disableTypography/>
                                {filmTypeOpen ? <ExpandLess className="icon"/> : <ExpandMore className="icon"/>}
                            </ListItemButton>
                            <Collapse in={filmTypeOpen} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {[...filmTypeMap.keys()].map((type, index) => (
                                        <ListItemButton sx={{backgroundColor: type===currentClick? 'lightgrey':'transparent'}} key={index} onClick={()=>filmTypeClick(type)}>
                                            <ListItemText className="listChildButton" primary={
                                                isMobile?
                                                    type
                                                    :
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        width: '90%'
                                                    }}>
                                                        <span>{type}</span>
                                                        <span style={{fontSize: '0.8rem', alignContent: 'center'}}>{filmTypeMap.get(type).length}</span>
                                                    </div>
                                            } disableTypography/>
                                        </ListItemButton>
                                    ))}
                                </List>
                            </Collapse>

                            <ListItemButton onClick={expandTime}>
                                <ListItemText className="listFatherButton" primary="By Time" disableTypography/>
                                {timeOpen ? <ExpandLess className="icon"/> : <ExpandMore className="icon"/>}
                            </ListItemButton>
                            <Collapse in={timeOpen} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {[...timeMap.keys()].map((time, index) => (
                                        <ListItemButton sx={{backgroundColor: time===currentClick? 'lightgrey':'transparent'}} key={index} onClick={()=>timeClick(time)}>
                                            <ListItemText className="listChildButton" primary={
                                                isMobile?
                                                    time
                                                    :
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        width: '90%'
                                                    }}>
                                                        <span>{time}</span>
                                                        <span style={{fontSize: '0.8rem', alignContent: 'center'}}>{timeMap.get(time).length}</span>
                                                    </div>
                                            } disableTypography/>
                                        </ListItemButton>
                                    ))}
                                </List>
                            </Collapse>
                            <ListItemButton disabled>
                                <ListItemText sx={{fontSize: isMobile? '0.4rem' : '0.8rem'}} className="listFatherButton" primary='Last Update: Feb 7, 2025' disableTypography/>
                            </ListItemButton>
                        </List>
                    </Box>
                </Grid>
                <Grid item xs={9}>
                    <Box sx={{ width: '100%'}}>
                        <Masonry columns={3} spacing={spacing}>
                            { currentTab === 'location' && locationMap.get(currentClick)?.map((image,index)=>(
                                <Box
                                    key={image}
                                    component="img"
                                    src={`${process.env.PUBLIC_URL}/pics/${image}.jpg`}
                                    className="smallImage"
                                    onClick={() => imageClick(image)}
                                />
                            ))}
                            { currentTab === 'filmType' && filmTypeMap.get(currentClick)?.map((image,index)=>(
                                <Box
                                    key={image}
                                    component="img"
                                    src={`${process.env.PUBLIC_URL}/pics/${image}.jpg`}
                                    className="smallImage"
                                    onClick={() => imageClick(image)}
                                />
                            ))}
                            { currentTab === 'time' && timeMap.get(currentClick)?.map((image,index)=>(
                                <Box
                                    key={image}
                                    component="img"
                                    src={`${process.env.PUBLIC_URL}/pics/${image}.jpg`}
                                    className="smallImage"
                                    onClick={() => imageClick(image)}
                                />
                            ))}
                        </Masonry>
                    </Box>
                </Grid>
            </Grid>
            <Dialog open={imageOpen} onClose={imageCloseClick} maxWidth={false}
                    sx={{width: '100%', backgroundColor: 'transparent'}}
                    PaperProps={{
                        sx: {
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                        }
                    }}
            >
                <IconButton
                    color="lightgrey"
                    onClick={imageCloseClick}
                    aria-label="close"
                    sx={{ position: 'fixed', right: 8, top: 8, "&:hover": {backgroundColor: 'rgba(229,229,229,0.35)'} }}
                >
                    <CloseIcon />
                </IconButton>
                <Box
                    component="img"
                    src={`${process.env.PUBLIC_URL}/pics/${selectedImage}.jpg`}
                    sx={{
                        // height: '85vh',
                        width: 'auto',
                        maxHeight: '85vh',
                        objectFit: 'contain'
                    }}
                />
            </Dialog>
        </Box>
    );
}

export default Photos;
